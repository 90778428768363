.orderItem {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 8px;
}

.productDetails {
  display: flex;
  align-items: flex-start;
  margin-right: 12px;
}

.thumbnailContainer {
  display: flex;
  position: relative;
}
.orderThumbnail {
  border-radius: 16px;
}
.productColor {
  position: absolute;
  right: -1px;
  top: -1px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  color: black;
  z-index: 999;
}

.productName {
  margin-left: 23px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  width: 60%;
  color: #1c3142;
  opacity: 90%;
  letter-spacing: 1.2px;
  line-height: 20px;
  text-transform: none;
  word-break: all;
}

.quantityControls {
  width: 124px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product_specs {
  font-size: 12px;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.7px;
  color: #656565;
  font-weight: 300;
  margin-top: 7.5px;
}
.details_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productPrice {
  margin-right: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #1c3142;
}

.attachmentLink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.fileName {
  margin-left: 5px;
  font-size: 11px;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
