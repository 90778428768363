.carouselImageContainer :global(.carousel) {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  top: -20px;
}

.carouselImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-bottom: 30px;
}

.carouselImageContainer :global(.carousel .control-dots .dot) {
  background: #444444 !important;
  box-shadow: none !important;
  transition: none !important;
  height: 7px !important;
  width: 7px !important;
  border-radius: 50% !important;
}

.carouselImageContainer :global(.carousel .control-dots .dot.selected) {
  background: #000 !important;
}

.carouselVideoContainer {
  @apply absolute top-0 left-0 w-full h-full object-cover;
}

.carouselVideoContainer video {
  @apply absolute top-0 left-0 w-full h-full object-cover;
}
