.infobanner {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(255, 196, 0);
  position: sticky;
  padding: 20px;
  max-width: var(--max-width);
  font-size: 18px;
  font-stretch: normal;
  overflow: visible;
  top: 0;
  font-style: normal;
  font-family: "Lato", sans-serif;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-align: center;
  z-index: 1000;
}
