.container {
  min-height: 100vh;
  max-width: 100vw;
  /* max-width: var(--max-width); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
}

.cart {
  height: 28px;
  width: 24px;
  background: none;
  outline: none;
  border: none;

  background-image: url("/shopping-bag.svg");
  background-position: center;
  background-size: cover;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profilePic {
  width: 102px;
  height: 102px;
  border-radius: 50%;
  object-fit: cover;
}

.footerTagline {
  /* display: flex;
  flex-direction: column; */

  margin-left: 16px;
  font-weight: bold;
}

.notFound {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notFoundMessage {
  margin: 0 36px 24px 36px;
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.notFoundHeadHome {
  margin: 11px 55px 175.5px 55.5px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: center;
  color: #1c3142;
  text-decoration: underline;
}

.link {
  text-decoration: underline;
  color: blue;
}

.title404 {
  margin-bottom: 2rem;
  font-size: 4rem;
}

@media (max-width: 600px) {
}
